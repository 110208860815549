import Wallpaper from "./images/homebackground.jpg";
import theme from "../../utils/theme";

const styles = ({ palette, breakpoints }) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    width: "100%",
    flexDirection: "column"
  },

  divider: {
    width: "100%"
  },

  input: {
    marginLeft: 8,
    flex: 1
  },

  brandName: {
    color: "white",
    marginLeft: 15,
    marginTop: 15
  },

  logout: {
    justifyContent: " flex-end",
    marginRight: 20,
    marginTop: 10,
  },

  logoutIcon: {
    color: "white",
    fontSize: 40,
    
  },

  search: {
    display: "flex",
    flex: 1,
    width: "50%",
    alignSelf: "center",
    alignItems: "start",
    flexDirection: "column"
  },

  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },

  iconButton: {
    padding: 10
  },

  divider: {
    width: 1,
    height: 28,
    margin: 4
  },

  titleContainer: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center"
  },

  title: {
    alignSelf: "center",
    justifyContent: "center",
    fontSize: 120,
    color: "white"
  },

  button: {
    backgroundColor: "white",
    width: "49%"
  },

  buttons: {
    display: "flex",
    justifyContent: "space-around",
    marginTop: 10,
    marginBottom: 7,
    width: "100%"
  },

  loader: {
    ...theme.loader,
  },

  background: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    backgroundImage: `url(${Wallpaper})`,
    backgroundSize: "auto 100%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    height: "100vh"
  },

  categories: {
    display: "flex",
    width: "100%",
    margin: '10px 0px',
  },
  
  categorySelection:{
    color: 'black',
  }
});

export default styles;
