import { Typography, Button } from "@material-ui/core";
import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";

import styles from "./styles";
import NavBar from "../../common/NavBar";
import ProductList from "./ProductList";
import CHECKOUT from "../../core/CHECKOUT";
import { Mutation } from "react-apollo";
import { HOME } from "../../utils/routes";

class MainScreen extends Component {
  state = {
    favorites: 0,
    showFavorites: false
  };

  handleFavorites = isCheck => {
    const { favorites } = this.state;
    const newFavorites = isCheck ? favorites + 1 : favorites - 1;
    this.setState({ favorites: newFavorites });
  };

  handleShowFavorites = () => {
    this.setState(prevState => ({ showFavorites: !prevState.showFavorites }));
  };

  renderSubtitle = () => {
    const { showFavorites } = this.state;
    const {
      classes,
      location: { state }
    } = this.props;

    return (
      <Typography className={classes.subtitle}>
        {showFavorites ? "Favorites" : state.subtitle}
      </Typography>
    );
  };

  onCheckout = (func) => {
    const { history } = this.props;
    history.push(HOME);
    func();
  }

  renderCheckoutButton = () => {
    const { classes } = this.props;
    return (
      <Mutation
        mutation={CHECKOUT}
        variables={{
          userId: window.localStorage.getItem("userId"),
        }}
      >
        {checkoutProductMutation => (
          <Button
            variant="contained"
            color="primary"
            className={classes.checkoutButton}
            onClick={() => this.onCheckout(checkoutProductMutation)}
          >
            Checkout
          </Button>
        )}
      </Mutation>
    );
  };

  render() {
    const { favorites, showFavorites } = this.state;
    const {
      classes,
      history,
      location: { state }
    } = this.props;
    if (state) {
      return (
        <Fragment>
          <div>
            <NavBar
              className={classes.menu}
              handleShowFavorites={this.handleShowFavorites}
              favorites={favorites}
              history={history}
            />
          </div>
          <div className={classes.titleContainer}>
            {this.renderSubtitle()}
            {showFavorites && this.renderCheckoutButton()}
          </div>
          <ProductList
            products={state.products}
            history={history}
            handleFavorites={this.handleFavorites}
            favItems={favorites}
            updateBasket={fav => this.setState({ favorites: fav })}
            filterByFavorites={showFavorites}
          />
        </Fragment>
      );
    } else {
      return "You need to return Home";
    }
  }
}

export default withStyles(styles)(MainScreen);
