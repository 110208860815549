import { Route } from "react-router";
import React, { Component } from "react";
import { HttpLink } from "apollo-link-http";
import { ApolloProvider } from "react-apollo";
import { Switch, Redirect } from "react-router-dom";
import { InMemoryCache } from "apollo-cache-inmemory";

import "./App.css";
import { store } from "./redux";
import { client } from "./apollo";
import { Provider } from "react-redux";
import { HOME, MAIN, PRODUCT, APP } from "./utils/routes";
import HomeScreen from "./components/HomeScreen";
import MainScreen from "./components/MainScreen";
import ProductDetail from "./components/ProductDetail";

class App extends Component {
  render() {
    return (
      <ApolloProvider client={client}>
        <Provider store={store}>
          <Switch>
            <Route path={HOME} component={HomeScreen} />
            <Route path={MAIN} component={MainScreen} />
            <Route path={PRODUCT} component={ProductDetail} />
            <Redirect exact from={APP} to={HOME} />
          </Switch>
        </Provider>
      </ApolloProvider>
    );
  }
}

export default App;
