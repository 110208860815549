import gql from 'graphql-tag';

const GET_USER_BY_NAME = gql`
  query GetUserByName($name: String!) {
    userByName(username: $name) {
      id
      name
      avatar
      favoriteProductsId
    }
  }
`;

export default GET_USER_BY_NAME;