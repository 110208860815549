const styles = ({ palette, breakpoints, loader }) => ({
  background: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    backgroundColor: "white",
    alignItems: "center",
    backgroundSize: "auto 100%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    height: "100vh"
  },

  loader: {
    loader
  },

  card: {
    display: "flex",
    height: "auto",
    width: "90%",
    overflow: "auto",
    margin: 6,
    [breakpoints.down("xs")]: {
      flexDirection: "column"
    }
  },

  details: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    maxHeight: 340,
    overflow: "auto",
    [breakpoints.down("xs")]: {
      overflow: "unset"
    }
  },

  content: {
    flex: "1 0 auto"
  },

  carousel: {
    width: "40%",
    [breakpoints.down("xs")]: {
      width: "100%"
    }
  },

  name: {
    textAlign: "center",
    margin: 10,
    fontWeight: 550
  },

  price: {
    fontWeight: 500,
    fontSize: 20
  },

  sold: {
    fontSize: 18
  },

  promotion: {
    fontSize: 18,
    color: "red"
  },

  goBack: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    position:'absolute',
    top:76,
    left:5,
  },
  
  numericInfo: {
    margin: 10,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  }
});

export default styles;
