import React, { Component } from "react";
import { withStyles, Tooltip } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import queryString from "query-string";
import CardMedia from "@material-ui/core/CardMedia";
import CardActionArea from "@material-ui/core/CardActionArea";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import Icon from "@material-ui/core/Icon";

import { PRODUCT } from "../../utils/routes";

import styles from "./styles";
import { isPlaceholder } from "@babel/types";
import { IMG_PLACEHOLDER } from "../../utils/strings";
import ADD_FAVORITE from "../../core/ADD_FAVORITE";
import { Mutation } from "react-apollo";

class Product extends Component {
  constructor(props) {
    super(props);
    const { favorite } = props;
    this.state = {
      isFav: favorite
    };
  }

  handleFavProduct = () => {
    const { handleFavorites } = this.props;
    const { isFav } = this.state;
    this.setState(
      prevState => ({ isFav: !prevState.isFav }),
      handleFavorites(!isFav)
    );
  };

  openProductDetails = () => {
    const { history, product } = this.props;
    const params = {
      id: product.id
    };
    const stringified = queryString.stringify(params);
    history.push(`${PRODUCT}?${stringified}`);
  };

  renderImg = () => {
    const { product } = this.props;
    return product.images[0] || IMG_PLACEHOLDER;
  };

  renderProductCard = tooltipText => {
    const { classes, product } = this.props;
    const { isFav } = this.state;

    return (
      <Tooltip title={tooltipText} classes={{ tooltip: classes.customWidth }} >
        <Card className={classes.card}>
          <CardActionArea onClick={this.openProductDetails}>
            <CardMedia className={classes.media} image={this.renderImg()} />
            <CardContent>
              <Typography
                className={classes.title}
                gutterBottom
                variant="h5"
                component="h2"
              >
                {product.name}
              </Typography>
              <Typography className={classes.price} component="p">
                {`$${product.price}`}
              </Typography>
            </CardContent>
          </CardActionArea>
          <Mutation
            mutation={ADD_FAVORITE}
            variables={{
              userId: window.localStorage.getItem("userId"),
              productId: product.id
            }}
          >
            {addFavoriteMutation => (
              <button className={classes.button} onClick={addFavoriteMutation}>
                <Icon
                  onClick={this.handleFavProduct}
                  className={`${isFav && classes.activeIcon} ${classes.icon}`}
                >
                  {isFav ? "favorite" : "favorite_border"}
                </Icon>
              </button>
            )}
          </Mutation>
        </Card>
      </Tooltip>
    );
  };

  render() {
    const { classes, product, onCart } = this.props;
    console.log("onCart ", onCart);
    const tooltipText = onCart ? product.description.substring(0, 250) : "";
    return this.renderProductCard(tooltipText);
  }
}

export default withStyles(styles)(Product);
