const styles = ({ palette, breakpoints }) => ({
  card: {
    marginBottom: 30,
    width: "30%",
    opacity: 0.95,
    marginTop: 34
  },

  media: {
    height: 140
  },

  inputContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },

  inputSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: "100%",
  },

  input: {
    marginTop: 40
  },
  
  title: {
    marginTop: 40,
    fontSize: 30,
    color: "#3d4eab"
  },

  subTitle: {
    fontSize: 15
  },

  signInButton: {
    marginTop: 30,
    width: 150
  }
});

export default styles;