import { createMuiTheme } from "@material-ui/core/styles";

const breakpoints = {
  keys: ["xxs", "xs", "sm", "md", "lg", "xl", "vp1", "vp2", "vp3"],
  values: {
    xxs: 0,
    xs: 370,
    sm: 600,
    md: 768,
    lg: 992,
    xl: 1200,
    vp1: 1360,
    vp2: 1300,
    vp3: 1110
  }
};

const loader = {
  display: 'flex',
  justifyContent: "center",
  alignItems:'center',
};

const theme = createMuiTheme({
  
  palette: {
    common: {
      50: "#2e3133",
      100: "#72777a",
      200: "#94999c",
      300: "#cbcfd1",
      400: "#edeff0",
      500: "#434343",
      600: "#ffffff",
      700: "#fafafa",
      800: "#1d1f20",
      900: "#777777"
    },
    primary: {
      50: "#164976",
      100: "#2b74b2",
      200: "#5e88b2",
      300: "#5c809f",
      400: "#39648b",
      500: "#103963",
      600: "#E4EDF5",
      700: "#164976",
      800: "#2b74b2",
      900: "#5e88b2",
      A100: "#7baaff",
      A200: "#4889ff",
      A400: "#164976",
      A700: "#0059fb",
      A800: "#485D6C",
      contrastDefaultColor: "light"
    },
    secondary: {
      50: "#fff5ef",
      100: "#FFEEE8",
      200: "#9b2700",
      300: "#eaaf84",
      400: "#c96b26",
      500: "#bf5100",
      600: "#b94a00",
      700: "#ff6c00",
      800: "#ffb884",
      900: "#E2660C",
      A100: "#ffd0c6",
      A200: "#eccbb3",
      A400: "#ff7c60",
      A700: "#ff6747",
      contrastDefaultColor: "light"
    },
    error: {
      50: "#fee6e6",
      100: "#fcc0c0",
      200: "#fa9696",
      300: "#f76c6c",
      400: "#f64d4d",
      500: "#f12222",
      600: "#f42d2d",
      700: "#ff8686",
      800: "#ef1c1c",
      900: "#ec1111",
      A100: "#ffffff",
      A200: "#ffe5e5",
      A400: "#ffb3b3",
      A700: "#ff9999",
      contrastDefaultColor: "light"
    },
    success: {
      50: "#e3f6ed",
      100: "#bae8d3",
      200: "#8cd9b5",
      300: "#5dc997",
      400: "#3bbe81",
      500: "#18b26b",
      600: "##a2f4ce",
      700: "#11a258",
      800: "#0e994e",
      900: "#088a3c",
      A100: "#b8ffd0",
      A200: "#85ffaf",
      A400: "#51ff8d",
      A700: "#38ff7d",
      contrastDefaultColor: "light"
    },
    action: {
      selected: "#ffb884"
    }
  },
  overrides: {
    MuiToolbar: {
      root: {
        zIndex: 1,
      },
    },
  },
  breakpoints

});

export default theme;
