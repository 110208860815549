const styles = ({ palette, breakpoints }) => ({
  img: {
    width: 250,
    maxWidth: 280,
    minWidth: 280,
    minHeight: 340,
  },
});

export default styles;
