import { Query } from "react-apollo";
import queryString from "query-string";
import React, { Fragment } from "react";
import Icon from "@material-ui/core/Icon";
import Card from "@material-ui/core/Card";
import Divider from "@material-ui/core/Divider";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import { CircularProgress, IconButton } from "@material-ui/core";

import styles from "./styles";
import Tabs from "../../common/Tabs";
import Slides from "../../common/Slides";
import NavBar from "../../common/NavBar";
import { MAIN } from "../../utils/routes";
import GET_PRODUCT from "../../core/GET_PRODUCT";
import GET_CATEGORY_PRODUCTS from "../../core/GET_CATEGORY_PRODUCTS";

const ProductDetail = props => {
  const { classes, history, location, favorites } = props;

  const showProducts = data => {
    history.goBack();
  };

  const renderBack = product => {
    return (
      <div className={classes.goBack}>
        <IconButton
          onClick={() => showProducts()}
          className={classes.iconButton}
        >
          <Icon className={classes.back}>{"arrow_back"} </Icon>
        </IconButton>
        <Typography
          className={classes.price}
          component="subtitle1"
          variant="Display 2"
        >
          Go Back
        </Typography>
      </div>
    );
  };

  const data = product => ({
    tab1: "Description",
    tab2: "Dimensions",
    text1: product.description,
    text2: product.dimensions
  });

  const calculatePromotion = (discount, price) => {
    return price - price * (discount / 100);
  };

  const renderNumericInfo = ({ price, promotion, amountSold }) => (
    <div className={classes.numericInfo}>
      <Typography
        className={classes.price}
        component="subtitle1"
        variant="Display 2"
      >
        {`Price: $${price}`}
      </Typography>
      <Typography
        className={classes.sold}
        component="subtitle1"
        variant="Display 2"
      >
        {`Items Sold: ${amountSold}`}
      </Typography>
      <Typography
        className={classes.promotion}
        component="subtitle1"
        variant="Display 2"
      >
        {promotion > 0 &&
          `Promotional Price: $${calculatePromotion(promotion, price)}`}
      </Typography>
    </div>
  );

  const renderDetails = product => (
    <CardContent className={classes.content}>
      <Typography component="h5" variant="h5" className={classes.name}>
        {product.name}
      </Typography>
      <Divider style={{ width: "100%" }} />
      {renderNumericInfo(product)}
      <Divider style={{ width: "100%" }} />
      <Tabs data={data(product)} />
    </CardContent>
  );

  const renderCarousel = product => (
    <div className={classes.carousel}>{<Slides images={product.images} />}</div>
  );

  const params = queryString.parse(location.search);

  return (
    <Query query={GET_PRODUCT} variables={{ id: params.id }}>
      {({ loading, error, data }) => {
        if (loading) return <CircularProgress />;
        if (error) return "Error";
        return (
          <Fragment>
            <NavBar history={history} hideFavorites />
            <div className={classes.background}>
              {renderBack(data.product)}
              <Card className={classes.card}>
                {renderCarousel(data.product)}
                <div className={classes.details}>
                  {renderDetails(data.product)}
                </div>
              </Card>
            </div>
          </Fragment>
        );
      }}
    </Query>
  );
};

export default withStyles(styles)(ProductDetail);
