import React, { Component } from "react";
import Menu from "@material-ui/core/Menu";
import Icon from "@material-ui/core/Icon";
import Badge from "@material-ui/core/Badge";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import MenuItem from "@material-ui/core/MenuItem";
import MoreIcon from "@material-ui/icons/MoreVert";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import Basket from "@material-ui/icons/ShoppingBasket";
import AccountCircle from "@material-ui/icons/AccountCircle";

import styles from "./styles";
import MenuStack from "../MenuStack";
import { WELCOME, HOME } from "../../utils/routes";

class NavBar extends Component {
  state = {
    anchorEl: null,
    mobileMoreAnchorEl: null
  };

  handleProfileMenuOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    const { history } = this.props;
    this.setState({ anchorEl: null });
    this.handleMobileMenuClose();
    history.push(WELCOME);
  };

  handleMobileMenuOpen = event => {
    this.setState({ mobileMoreAnchorEl: event.currentTarget });
  };

  handleMobileMenuClose = () => {
    const { handleShowFavorites } = this.props;
    this.setState({ mobileMoreAnchorEl: null });
  };

  handleMobileMenuCloseFav = () => {
    const { handleShowFavorites } = this.props;
    this.setState({ mobileMoreAnchorEl: null });
    handleShowFavorites();
  };

  render() {
    const { classes, favorites, history, handleShowFavorites, hideFavorites } = this.props;
    const { anchorEl, mobileMoreAnchorEl } = this.state;
    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const renderMenu = (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isMenuOpen}
        onClose={this.handleMenuClose}
      >
        <MenuItem onClick={this.handleMenuClose}>Logout</MenuItem>
      </Menu>
    );

    const renderMobileMenu = (
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isMobileMenuOpen}
        onClose={this.handleMenuClose}
      >
        {!hideFavorites && (
          <MenuItem onClick={this.handleMobileMenuCloseFav}>
            <IconButton color="inherit">
              <Badge badgeContent={favorites} color="secondary">
                <Basket />
              </Badge>
            </IconButton>
            <p>Cart</p>
          </MenuItem>
        )}
        <MenuItem onClick={this.handleProfileMenuOpen}>
          <IconButton color="inherit">
            <AccountCircle />
          </IconButton>
          <p>Profile</p>
        </MenuItem>
      </Menu>
    );

    return (
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar>
            <IconButton
              onClick={() => history.push(HOME)}
              className={classes.title}
              color="inherit"
            >
              <Icon className={classes.logoutIcon}>{"home"} </Icon>
            </IconButton>
            {!hideFavorites && (
              <div className={classes.menu}>
                <MenuStack history={history} />
              </div>
            )}

            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              {!hideFavorites && (
                <IconButton onClick={handleShowFavorites} color="inherit">
                  <Badge badgeContent={favorites} color="secondary">
                    <Basket />
                  </Badge>
                </IconButton>
              )}
              <IconButton
                aria-owns={isMenuOpen ? "material-appbar" : undefined}
                aria-haspopup="true"
                onClick={this.handleProfileMenuOpen}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
            </div>
            <div className={classes.sectionMobile}>
              <IconButton
                aria-haspopup="true"
                onClick={this.handleMobileMenuOpen}
                color="inherit"
              >
                <MoreIcon />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        {renderMenu}
        {renderMobileMenu}
      </div>
    );
  }
}

export default withStyles(styles)(NavBar);
