import gql from 'graphql-tag';

const GET_USER = gql`
  query GetUser($id: ID!) {
    user(id: $id) {
      id
      name
      avatar
      favoriteProductsId
    }
  }
`;

export default GET_USER;