import gql from 'graphql-tag';

const GET_CATEGORY_PRODUCTS = gql`
  query GetCategoryProducts($id: ID!) {
    productsByCategory(id: $id ) {
      id
      name
      description
      price
      dimensions
      images
    }
  }
`;

export default GET_CATEGORY_PRODUCTS;