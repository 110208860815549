const styles = ({ palette, breakpoints }) => ({
  card: {
    maxWidth: 345,
    margin: 8
  },
  media: {
    height: 140,
    backgroundRepeat: "no-repeat",
    backgroundSize: "auto 100%"
  },
  title: {
    fontWeight: 100,
    whiteSpace: "nowrap",
    width: 300,
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  price: {
    fontSize: 22,
    fontWeight: 500
  },
  icon: {
    "&:hover": {
      color: palette.secondary[`A400`]
    },
    position: "absolute",
    bottom: 21,
    right: 21,
    cursor: "pointer"
  },
  activeIcon: {
    color: palette.secondary[`A400`]
  },
  bottomContent: {
    display: "flex",
    justifyContent: "space-between"
  },
  button: {
    backgroundColor: "white",
    border: "none",
    display: "contents",
  },
  customWidth: {
    maxWidth: 180,
  }
});

export default styles;
