import gql from 'graphql-tag';

const LOGIN = gql`
  mutation login($username: String!, $photo: String) {
    login(username: $username, photo: $photo) {
      id
      name
      avatar
      favoriteProductsId
    }
  }
`;

export default LOGIN;