import gql from 'graphql-tag';

const GET_PRODUCT = gql`
  query GetProduct($id: ID!) {
    product(id: $id ) {
      id
      name
      description
      price
      dimensions
      images
      category
      amountSold
      promotion
    }
  }
`;

export default GET_PRODUCT;