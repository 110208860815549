import Wallpaper from "../../images/wallpaper.jpeg";

const styles = ({ palette, breakpoints }) => ({
  card: {
    padding: 20,
    marginBottom: 30,
    width: "30%"
  },

  background: {
    display: "flex",
    justifyContent: "space-around",
    backgroundImage: `url(${Wallpaper})`,
    backgroundSize: "auto 100%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    height: "100vh"
  },

  title: {
    alignSelf: "center",
    fontSize: 120,
    color: "white"
  }
});

export default styles;
