const styles = theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper
  },
  
  loader: {
    ...theme.loader,
  },

  gridList: {
    height: "auto",
    display: "flex",
    marginTop: " 10px !important",
    justifyContent: "space-around"
  },
  listTile: {
    height: "auto !important",
    width: "340px !important"
  },
});

export default styles;
