import ReactDOM from "react-dom";
import { Route } from "react-router";
import React, { Fragment } from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { ApolloProvider } from "react-apollo";
import { client } from "./apollo";

import "./index.css";
import App from "./App";
import theme from "./utils/theme";
import { WELCOME, APP } from "./utils/routes";
import * as serviceWorker from "./serviceWorker";
import WelcomeScreen from "../src/components/WelcomeScreen";

const render = (
  <Fragment>
    <BrowserRouter>
      <ApolloProvider client={client}>
        <MuiThemeProvider theme={theme}>
          <Switch>
            <Route path={APP} component={App} />
            <Route path={WELCOME} component={WelcomeScreen} />
          </Switch>
        </MuiThemeProvider>
      </ApolloProvider>
    </BrowserRouter>
  </Fragment>
);

ReactDOM.render(render, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
