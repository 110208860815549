import React, { Fragment } from "react";
import { withStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

import Login from "../Login";
import styles from "./styles";

const WelcomeScreen = props => {
  const { classes, history } = props;
  return (
    <Fragment>
      <div className={classes.background}>
        <Typography className={classes.title} component="h2" variant="display1">
          {"Shop Online."}
        </Typography>
        <Login history={history} />
      </div>
    </Fragment>
  );
};

export default withStyles(styles)(WelcomeScreen);
