import React, { Component } from "react";
import { withStyles } from "@material-ui/core";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import { IMG_PLACEHOLDER } from "../../utils/strings";

import styles from "./styles";

class Slides extends Component {
  renderImages = () => {
    const { images, classes } = this.props;
    if (images[0]) {
      return images.map(image => <img className={classes.img} src={image} />);
    }
    return <img className={classes.img} src={IMG_PLACEHOLDER} />;
  };

  render() {
    return (
      <Carousel infiniteLoop showThumbs={false}>
        {this.renderImages()}
      </Carousel>
    );
  }
}

export default withStyles(styles)(Slides);
