import React from "react";
import PropTypes from "prop-types";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 2 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
};

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  description: {
    overflow: "auto"
  },
  tabs:{ backgroundColor: '#717171',
  }
});

class SimpleTabs extends React.Component {
  state = {
    value: 0
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  render() {
    const { classes, data } = this.props;
    const { value } = this.state;

    return (
      <div className={classes.root}>
        <AppBar position="static" className= {classes.tabs}>
          <Tabs value={value} onChange={this.handleChange}>
            <Tab label={data.tab1} />
            <Tab label={data.tab2} />
          </Tabs>
        </AppBar>
        {value === 0 && (
          <TabContainer>
            <Typography
              variant="body2"
              color="textSecondary"
              className={classes.description}
            >
              {data.text1}
            </Typography>
          </TabContainer>
        )}
        {value === 1 && (
          <TabContainer>
              <Typography
                variant="body2"
                color="textSecondary"
                className={classes.description}
              >
                {data.text2}
              </Typography>
          </TabContainer>
        )}
      </div>
    );
  }
}

SimpleTabs.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SimpleTabs);
