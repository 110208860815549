import React, { Component } from "react";
import { Mutation } from "react-apollo";
import Card from "@material-ui/core/Card";
import Input from "@material-ui/core/Input";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";

import styles from "./styles";
import LOGIN from "../../core/LOGIN";
import { HOME } from "../../utils/routes";
import AvatarImage from "../../images/get-it.jpeg";

class Login extends Component {
  state = {
    username: "",
    photo: ""
  };

  renderText = () => {
    const { classes } = this.props;
    return (
      <div className={classes.textContainer}>
        <Typography className={classes.title} component="h2" variant="display1">
          {"Login to get going "}
        </Typography>
      </div>
    );
  };

  renderInputs = () => {
    const { classes } = this.props;
    const { username, photo } = this.state;

    return (
      <div className={classes.inputContainer}>
        <Input
          value={username}
          className={classes.input}
          onChange={e => this.setState({ username: e.target.value })}
          placeholder={"Username"}
        />
        <Input
          value={photo}
          className={classes.input}
          onChange={e => this.setState({ photo: e.target.value })}
          placeholder={"Photo"}
        />
      </div>
    );
  };

  login = (loginMutation, username) => {
    const { history } = this.props;
    loginMutation();
    window.localStorage.setItem('username', username);
    history.push(HOME);
  }

  render() {
    const { classes, history } = this.props;
    const { username, photo } = this.state;
    return (
      <Card className={classes.card}>
        <CardMedia className={classes.media} image={AvatarImage} title="Logo" />
        <div className={classes.inputSection}>
          {this.renderText()}
          {this.renderInputs()}
          <Mutation
            mutation={LOGIN}
            variables={{
              username,
              photo
            }}
          >
            {(loginMutation, { data }) => (
              <Button
              variant="contained"
              color="primary"
              className={classes.signInButton}
              onClick={() => this.login(loginMutation, username)}
            >
              {"Sign in"}
            </Button>
            )}
          </Mutation>
          
        </div>
      </Card>
    );
  }
}

export default withStyles(styles)(Login);
