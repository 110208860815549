import React from "react";
import { Query } from "react-apollo";
import GridList from "@material-ui/core/GridList";
import { CircularProgress, Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import GridListTile from "@material-ui/core/GridListTile";

import styles from "./styles";
import Product from "../../Product";
import GET_USER from "../../../core/GET_USER";
import GET_PRODUCTS from "../../../core/GET_PRODUCTS";

const ProductList = props => {
  const {
    classes,
    products,
    handleFavorites,
    history,
    favItems,
    updateBasket,
    filterByFavorites
  } = props;

  const userId = window.localStorage.getItem("userId");

  const isFavorite = (user, productId) => {
    return user.favoriteProductsId.find(pId => pId === productId);
  };

  const handleBasket = favorites => {
    if (favorites !== favItems && favItems === 0) {
      updateBasket(favorites);
    }
  };

  return (
    <Query query={GET_USER} variables={{ id: userId }}>
      {({ loading, error, data }) => {
        if (loading) return <CircularProgress />;
        if (error) return "Error";
        const { user } = data;
        handleBasket(user.favoriteProductsId.length);
        if (filterByFavorites) {
          return (
            <GridList className={classes.gridList}>
              <Query query={GET_PRODUCTS}>
                {({ loading, error, data }) => {
                  if (loading) return <CircularProgress />;
                  if (error) return "Error";
                  console.log("data ", data);
                  const { products } = data;
                  return products.map(product => {
                    return (
                      isFavorite(user, product.id) && (
                        <GridListTile className={classes.listTile}>
                            <Product
                              product={product}
                              history={history}
                              handleFavorites={handleFavorites}
                              favorite={isFavorite(user, product.id)}
                              onCart
                            />
                        </GridListTile>
                      )
                    );
                  });
                }}
              </Query>
            </GridList>
          );
        }
        console.log("products ", products);
        return (
          <div className={classes.root}>
            <GridList className={classes.gridList}>
              {products &&
                products.map(product => {
                  return (
                    <GridListTile className={classes.listTile}>
                      <Product
                        product={product}
                        history={history}
                        handleFavorites={handleFavorites}
                        favorite={isFavorite(user, product.id)}
                        onCart={false}
                      />
                    </GridListTile>
                  );
                })}
            </GridList>
          </div>
        );
      }}
    </Query>
  );
};

export default withStyles(styles)(ProductList);
