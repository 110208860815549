import { Query } from "react-apollo";
import React, { Fragment } from "react";
import Icon from "@material-ui/core/Icon";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";

import styles from "./styles";
import { BRAND_NAME } from "../../utils/strings";
import GET_PRODUCTS from "../../core/GET_PRODUCTS";
import { WELCOME, MAIN } from "../../utils/routes";
import findHotDeals from "../../utils/findHotDeals";
import GET_CATEGORIES from "../../core/GET_CATEGORIES";
import findBestSellers from "../../utils/findBestSellers";
import findUsedProducts from "../../utils/findUsedProducts";
import CircularProgress from "@material-ui/core/CircularProgress";
import GET_CATEGORY_PRODUCTS from "../../core/GET_CATEGORY_PRODUCTS";
import {
  HOT_DEALS,
  BEST_SELLERS,
  USED_PRODUCTS,
  HOME_MESSAGE
} from "./strings";
import GET_USER_BY_NAME from "../../core/GET_USER_BY_NAME";

const HomeScreen = props => {
  const { classes, history } = props;

  const showProducts = (data, subtitle) => {
    console.log('showProducts ');
    history.push({
      pathname: MAIN,
      state: { products: data, subtitle: subtitle}
    });
  };

  const isUserLogged = () => {
    return window.localStorage.getItem("username") !== null;
  };

  const renderCategories = ({ categories }) => {
    return categories.map(category => (
      <Query query={GET_CATEGORY_PRODUCTS} variables={{ id: category.id }}>
        {({ loading, error, data }) => {
          if (error) return "Missing";
          return (
            <Button
              variant="outlined"
              color="primary"
              className={classes.button}
              onClick={() =>
                showProducts(data.productsByCategory, category.name)
              }
            >
              {category.name}
            </Button>
          );
        }}
      </Query>
    ));
  };

  const renderButtons = () => {
    return (
      <Query query={GET_PRODUCTS}>
        {({ loading, error, data }) => {
          if (loading) return <CircularProgress className={classes.loader} />;
          if (error) return "error";
          return (
            <div className={classes.buttons}>
              <Button
                variant="outlined"
                color="primary"
                className={classes.button}
                onClick={() =>
                  showProducts(findBestSellers(data.products), "Best Sellers")
                }
              >
                {BEST_SELLERS}
              </Button>
              <Button
                variant="outlined"
                color="primary"
                className={classes.button}
                onClick={() =>
                  showProducts(findHotDeals(data.products), "Hot Deals")
                }
              >
                {HOT_DEALS}
              </Button>
              <Button
                variant="outlined"
                color="primary"
                className={classes.button}
                onClick={() =>
                  showProducts(findUsedProducts(data.products), "Used Products")
                }
              >
                {USED_PRODUCTS}
              </Button>
            </div>
          );
        }}
      </Query>
    );
  };

  const renderSearchBar = data => (
    <Fragment>
      <Paper className={classes.root} elevation={1}>
        <Typography
          className={classes.categorySelection}
          component="h4"
          variant="Headline"
        >
          Choose your category of interest:
        </Typography>
        <div className={classes.categories}>{renderCategories(data)}</div>
        <Divider style={{ width: "100%" }} variant="middle" />
        {renderButtons()}
      </Paper>
    </Fragment>
  );

  const renderHeader = () => (
    <div className={classes.header}>
      <Typography
        className={classes.brandName}
        component="h2"
        variant="display1"
      >
        {BRAND_NAME}
      </Typography>
      <div className={classes.logout}>
        <IconButton
          onClick={() => history.push(WELCOME)}
          className={classes.iconButton}
        >
          <Icon className={classes.logoutIcon}>{"exit_to_app"} </Icon>
        </IconButton>
      </div>
    </div>
  );

  if (isUserLogged()) {
    return (
      <Query query={GET_CATEGORIES}>
        {({ loading, error, data }) => {
          if (loading) return <CircularProgress className={classes.loader} />;
          if (error) return "error";
          return (
            <Query
              query={GET_USER_BY_NAME}
              variables={{ name: window.localStorage.getItem("username") }}
            >
              {({ loading, error, data: userData }) => {
                if (loading)
                  return <CircularProgress className={classes.loader} />;
                if (error) return "Error";
                window.localStorage.setItem("userId", userData.userByName.id);
                window.localStorage.setItem(
                  "userPhoto",
                  userData.userByName.avatar
                );
                return (
                  <Fragment>
                    <div className={classes.background}>
                      {renderHeader()}
                      <div className={classes.titleContainer}>
                        <Typography
                          className={classes.title}
                          component="h2"
                          variant="display1"
                        >
                          {HOME_MESSAGE}
                        </Typography>
                      </div>
                      <div className={classes.search}>
                        {renderSearchBar(data)}
                      </div>
                    </div>
                  </Fragment>
                );
              }}
            </Query>
          );
        }}
      </Query>
    );
  } else {
    return (
      "Log in to access the page"
    )
  }
};

export default withStyles(styles)(HomeScreen);
