const styles = theme => ({
    root: {
      display: "flex"
    },
    paper: {
      marginRight: theme.spacing.unit * 2
    },
    menuIcon: {
      color: "white"
    },
    category:{
      color:'white',
      fontWeight: 100,
    }
  });

  
export default styles;
