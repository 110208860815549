import React from "react";
import PropTypes from "prop-types";
import { Query } from "react-apollo";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import styles from "./styles";
import { MAIN } from "../../utils/routes";
import GET_CATEGORIES from "../../core/GET_CATEGORIES";
import CircularProgress from "@material-ui/core/CircularProgress";
import GET_CATEGORY_PRODUCTS from "../../core/GET_CATEGORY_PRODUCTS";

class MenuStack extends React.Component {
  state = {
    open: false
  };

  showProducts = (data, category) => {
    const { history } = this.props;
    history.push({
      pathname: MAIN,
      state: { products: data, subtitle: category.name }
    });
  };

  onClickMenuItem = (data, event, category) => {
    this.handleClose(event);
    this.showProducts(data.productsByCategory, category);
  };

  renderProductsByCategory = ({ categories }) => {
    return categories.map(category => (
      <Query query={GET_CATEGORY_PRODUCTS} variables={{ id: category.id }}>
        {({ loading, error, data }) => {
          if (error) return "Missing";
          return (
            <MenuItem
              onClick={event => this.onClickMenuItem(data, event, category)}
            >
              {category.name}
            </MenuItem>
          );
        }}
      </Query>
    ));
  };

  renderCategories = () => {
    const { classes } = this.props;
    return (
      <Query query={GET_CATEGORIES}>
        {({ loading, error, data }) => {
          if (loading) return <CircularProgress className={classes.loader} />;
          if (error) return "error";
          return this.renderProductsByCategory(data);
        }}
      </Query>
    );
  };

  handleToggle = () => {
    this.setState(state => ({ open: !state.open }));
  };

  handleClose = event => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }

    this.setState({ open: false });
  };

  render() {
    const { classes } = this.props;
    const { open } = this.state;

    return (
      <div className={classes.root}>
        <div>
          <Button
            buttonRef={node => {
              this.anchorEl = node;
            }}
            aria-owns={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            onClick={this.handleToggle}
          >
            <Typography
              className={classes.category}
              variant="h6"
              color="inherit"
              noWrap
            >
              Categories
            </Typography>
          </Button>
          <Popper open={open} anchorEl={this.anchorEl} transition disablePortal>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                id="menu-list-grow"
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom"
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={this.handleClose}>
                    <MenuList>{this.renderCategories()}</MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      </div>
    );
  }
}

MenuStack.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(MenuStack);
