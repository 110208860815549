import gql from 'graphql-tag';

const GET_PRODUCTS = gql`
  query GetProducts {
    products {
      id
      name
      description
      price
      images
      promotion
      amountSold
      category
      used
    }
  }
`;

export default GET_PRODUCTS;