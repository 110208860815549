import { createStore, combineReducers } from 'redux';

const initialState = {
  user: {
    username: 'Ash',
    avatar: '',
  },
};

const LOGIN = 'Auth/LOGIN';
const LOGOUT = 'Auth/LOGOUT';

export const login = user => ({
  type: LOGIN,
  user,
});

export const logout = () => ({
  type: LOGOUT,
});

export const userSelector = state => state.auth.user;

export const authReducer = (state = initialState, action) => {
  if (action.type === LOGIN) {
    return {
      ...state,
      user: action.user,
    };
  }
  if (action.type === LOGOUT) {
    return {
      ...state,
      trainer: null,
    };
  }
  return state;
};

const reducer = combineReducers({
  auth: authReducer,
});

export const store = createStore(
  reducer,
  {},
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);